
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapGetters } from 'vuex';
import { Note } from '@/types/types.d';
import Resize from '@/directives/resize';
import NoteCard from './NoteCard.vue';

@Options({
  components: {
    NoteCard,
  },
  directives: {
    Resize,
  },
  computed: {
    ...mapGetters('notes', [
      'allNotes',
    ]),
    ...mapActions('notes', [
      'listNotes',
    ]),
  },
  async mounted() {
    await this.listNotes;
  },
})
export default class Desktop extends Vue {
  backgroundImage = 'https://picsum.photos/1280/720'

  $refs!: {
    desktop: HTMLDivElement;
  }

  desktopSizes = {
    width: this.desktop?.clientWidth,
    height: this.desktop?.clientHeight,
  }

  allNotes!: () => Array<Note>;

  listNotes!: Function;

  get desktop() {
    return this.$refs?.desktop;
  }

  onResize({ detail }: CustomEvent): void {
    this.desktopSizes = {
      width: detail.width,
      height: detail.height,
    };
  }
}
