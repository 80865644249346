
import { Options, Vue } from 'vue-class-component';
import { EditorStyle } from '@/types/styles.d';
import { useModelWrapper } from '../utils/modelWrapper';

@Options({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    editorStyle: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup: ((props, { emit }) => ({
    content: useModelWrapper(props, emit),
  })),
})
export default class NoteEditor extends Vue {
  modelValue!: string

  editorStyle!: EditorStyle

  get content(): string {
    return this.modelValue;
  }

  set content(value) {
    this.$emit('update:modelValue', value);
  }
}
