
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    path: String,
    size: {
      type: Number,
      default: 24,
    },
  },
})
export default class App extends Vue {}
