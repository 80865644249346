
import { Vue, Options } from 'vue-class-component';
import { mapActions } from 'vuex';

@Options({
  methods: {
    ...mapActions('notes', [
      'addNewNote',
    ]),
  },
})
export default class AppHeader extends Vue {
  title = 'Minimal Notes';

  addNewNote!: () => void;
}
