
import { Options, Vue } from 'vue-class-component';
import { mdiTrashCan, mdiSync } from '@mdi/js';
import { mapActions } from 'vuex';
import { Note } from '@/types/types.d';
import { CardStyle, BarStyle, EditorStyle } from '@/types/styles.d';
import Resize, { Sizes } from '@/directives/resize';
import IconBase from '@/components/base/IconBase.vue';
import NoteEditor from './NoteEditor.vue';

@Options({
  components: {
    IconBase,
    NoteEditor,
  },
  directives: {
    Resize,
  },
  props: {
    note: Object,
    desktopSizes: Object,
  },
  methods: {
    ...mapActions('notes', [
      'updateNote',
      'removeNote',
    ]),
  },
  watch: {
    note: {
      deep: true,
      handler(note: Note) {
        this.handleNote(note);
      },
    },
  },
})
export default class NoteCard extends Vue {
  note!: Types.Note;

  desktopSizes!: Sizes;

  updateNote!: (note: Types.Note) => Promise<Types.Note>;

  appBarHeight = 96;

  isSyncing = false;

  syncInterval?: number;

  deleteIcon = mdiTrashCan;

  syncIcon = mdiSync;

  get cardStyle(): CardStyle {
    const top = this.note.y * this.desktopSizes.height;
    const left = this.note.x * this.desktopSizes.width;
    return {
      borderColor: this.note.color,
      top: `calc(${top}px)`,
      left: `${left}px`,
      width: `${this.note.width}px`,
      height: `${this.note.height}px`,
    };
  }

  get barStyle(): BarStyle {
    return {
      backgroundColor: this.note.color,
    };
  }

  get editorStyle(): EditorStyle {
    return {
      borderColor: this.note.color,
    };
  }

  onDrag({ clientX, clientY }: DragEvent): void {
    const x = clientX;
    const y = clientY - this.appBarHeight;
    if (clientX > 0 && y > 0) {
      this.note.x = x / this.desktopSizes.width;
      this.note.y = y / this.desktopSizes.height;
    }
  }

  onDragStart = (e: DragEvent): void => {
    if (e.dataTransfer) {
      const img = document.createElement('img');
      e.dataTransfer.setDragImage(img, 0, 0);
    }
  }

  onResize({ detail }: CustomEvent): void {
    this.note.height = detail.height;
    this.note.width = detail.width;
  }

  async handleNote(note: Types.Note) {
    try {
      await this.debounceNoteUpdate();
      this.isSyncing = true;
      setTimeout(async () => {
        await this.updateNote(note);
        this.isSyncing = false;
      }, 500);
    } catch (e) {
      this.isSyncing = false;
    }
  }

  debounceNoteUpdate(): Promise<void> {
    return new Promise((resolve: Function) => {
      if (this.syncInterval) {
        clearTimeout(this.syncInterval);
      }
      this.syncInterval = setTimeout(() => resolve(), 1000);
    });
  }
}

