
import { Options, Vue } from 'vue-class-component';
import AppHeader from './components/AppHeader.vue';
import Desktop from './components/Desktop.vue';

@Options({
  components: {
    AppHeader,
    Desktop,
  },
})
export default class App extends Vue {}
